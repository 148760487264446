// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React, { useEffect } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { globalHistory } from '@reach/router'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import { updateHeaderState } from '../../state/actions'

import MobileHeader from './mobile-header'
import DesktopHeader from './desktop-header'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
const Header = React.memo(
  ({
    pathname,
    mediaState: { currentWidth },
    pageSchema = {},
    updateHeaderState,
  }) => {
    useEffect(
      () =>
        globalHistory.listen(({ action }) => {
          if (action === 'PUSH') {
            updateHeaderState({ isActive: false, whatsActive: 'root' })

            if (typeof window !== 'undefined') {
              const element = document.getElementById('layout')
              element.scrollTop = 0
            }
          }
        }),
      [updateHeaderState]
    )

    return (
      <header>
        {currentWidth === 'w-xs' ||
        currentWidth === 'w-sm' ||
        currentWidth === 'w-md' ? (
          <div className="container">
            <MobileHeader pageSchema={pageSchema} pathname={pathname} />
          </div>
        ) : (
          <div>
            <DesktopHeader pageSchema={pageSchema} pathname={pathname} />
          </div>
        )}
      </header>
    )
  }
)

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    mediaState: state.mediaState,
  }),
  (dispatch) => ({
    updateHeaderState(payload) {
      dispatch(updateHeaderState(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedHeader = compose(
  withState // Add state
)(Header)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedHeader
