// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { Spring, animated } from 'react-spring'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
const SymbolFX = React.memo(({ isActive = false }) => (
  <svg viewBox="0 0 90 90">
    <title>Click to {isActive === true ? 'hide' : 'see'}</title>
    <Spring
      native
      reset
      reverse={isActive}
      from={{ cx: 20, cy: 20, r: 20, fill: '#8fbcbb' }}
      to={{ cx: 45, cy: 45, r: 44, fill: '#000' }}
    >
      {(props) => (
        <animated.circle
          fill={props.fill}
          cx={props.cx}
          cy={props.cy}
          r={props.r}
        />
      )}
    </Spring>
    <Spring
      native
      reset
      reverse={isActive}
      from={{ cx: 70, cy: 20, r: 20, fill: '#88c0d0' }}
      to={{ cx: 45, cy: 45, r: 44, fill: '#000' }}
    >
      {(props) => (
        <animated.circle
          fill={props.fill}
          cx={props.cx}
          cy={props.cy}
          r={props.r}
        />
      )}
    </Spring>
    <Spring
      native
      reset
      reverse={isActive}
      from={{ cx: 20, cy: 70, r: 20, fill: '#81a1c1' }}
      to={{ cx: 45, cy: 45, r: 44, fill: '#000' }}
    >
      {(props) => (
        <animated.circle
          fill={props.fill}
          cx={props.cx}
          cy={props.cy}
          r={props.r}
        />
      )}
    </Spring>
    <Spring
      native
      reset
      reverse={isActive}
      from={{ cx: 70, cy: 70, r: 20, fill: '#5e81ac' }}
      to={{ cx: 45, cy: 45, r: 44, fill: '#000' }}
    >
      {(props) => (
        <animated.circle
          fill={props.fill}
          cx={props.cx}
          cy={props.cy}
          r={props.r}
        />
      )}
    </Spring>
  </svg>
))

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default SymbolFX
