// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Nav from './nav'

import Cross from '../svg/cross'
import Homepage from '../svg/homepage'

import OneManySymbol from '../symbols/one-many'

import Link from '../link'
import '../link/style.less'

import Glider from './glider'
import './glider/style.less'

import { updateHeaderState } from '../../state/actions'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const MobileHeader = ({ headerState, updateHeaderState, pathname }) => (
  <Fragment>
    <Row>
      <Col xs={20} sm={20} md={20} lg={0} xl={0} xxl={0}>
        <p className="standard">
          <big>Integral Yoga Talks</big>
          <span>The Teachings of Sri Aurobindo and The Mother</span>
        </p>
      </Col>
      <Col
        xs={4}
        sm={4}
        md={4}
        lg={0}
        xl={0}
        xxl={0}
        style={{ textAlign: 'right' }}
      >
        <Link
          to="#"
          className="action not-this"
          onClick={(e) => {
            e.preventDefault()
            updateHeaderState({
              isActive: !headerState.isActive,
              whatsActive:
                headerState.isActive === false ? 'mobile-menu' : 'root',
            })
          }}
        >
          <OneManySymbol isActive={headerState.isActive} />
          <span>Menu</span>
        </Link>
      </Col>
    </Row>
    <Glider>
      <Row>
        <Col xs={20} sm={20} md={20} lg={0} xl={0} xxl={0}>
          <Nav pathname={pathname} />
        </Col>
        <Col xs={4} sm={4} md={4} lg={0} xl={0} xxl={0}>
          <Link
            to="#"
            className="action not-this"
            onClick={(e) => {
              e.preventDefault()
              updateHeaderState({
                isActive: !headerState.isActive,
                whatsActive:
                  headerState.isActive === false ? 'mobile-menu' : 'root',
              })
            }}
          >
            <Cross />
            <span>Close</span>
          </Link>
          <br />
          <Link to="/" className="action not-this">
            <Homepage />
            <span>Home</span>
          </Link>
        </Col>
      </Row>
    </Glider>
  </Fragment>
)

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    headerState: state.headerState,
  }),
  (dispatch) => ({
    updateHeaderState(payload) {
      dispatch(updateHeaderState(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedMobileHeader = compose(
  withState // Add state
)(MobileHeader)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedMobileHeader
