// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Method
// ----------------------------------------------------------------------------
/** [description] */
const getClosestIndex = (arr, target) => {
  let i = 0
  let j = arr.length - 1
  let k = 0
  let r = -1

  while (i <= j) {
    k = Math.floor((i + j) / 2)
    if (target === arr[k] || Math.abs(i - j) <= 1) {
      r = k
      break
    } else if (target < arr[k]) {
      j = k - 1
    } else {
      i = k + 1
    }
  }

  return r
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default getClosestIndex
