// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import isNumber from 'lodash/isNumber'

import getClosestIndex from './get-closest-index'

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Method
// ----------------------------------------------------------------------------
/** [description] */
const getNeighbour = (arr, target) => {
  const closest = getClosestIndex(arr, target)
  const result = [arr[closest]]

  if (isNumber(arr[closest - 1]) && isNumber(arr[closest + 1])) {
    if (
      Math.abs(target - arr[closest - 1]) < Math.abs(target - arr[closest + 1])
    ) {
      result.push(arr[closest - 1])
    } else {
      result.push(arr[closest + 1])
    }
  }

  return result
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default getNeighbour
