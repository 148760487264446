// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const CrossSvgSymbol = (props) => (
  <svg
    width={900}
    height={900}
    viewBox="0 0 900 900"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M194.316 43.103l255.306 255.438L705.059 43.103c99.898-99.898 252.93 50.428 151.598 151.765L601.35 450.173l255.306 255.438c99.757 99.58-52.01 251.346-151.598 151.598L449.622 601.903 194.316 857.209C94.559 956.965-57.03 805.199 42.551 705.61L297.99 450.173 42.55 194.868C-57.029 95.11 94.561-56.62 194.316 43.103z"
        fill="#000"
        fillRule="nonzero"
      />
      <path d="M0 0h900v900H0z" />
    </g>
  </svg>
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default CrossSvgSymbol
