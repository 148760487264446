// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

import flattenGatsbyNode from '../../methods/flatten-gatsby-node'
import strContains from '../../methods/str-contains'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

export const query = graphql`
  query {
    _audioTalks: allFile(
      filter: {
        fields: {
          pathAuto: { regex: "/^(?:(/audio-talks/))/" }
          autoNodeType: { eq: "resourceNode" }
          draftAuto: { eq: false }
        }
      }
      sort: { order: ASC, fields: [childResources___title] }
    ) {
      nodes {
        fields {
          pathAuto
        }
        childResources {
          title
        }
      }
    }
    _lectures: allFile(
      filter: {
        fields: {
          pathAuto: { regex: "/^(?:(/lectures/))/" }
          autoNodeType: { eq: "resourceNode" }
          draftAuto: { eq: false }
        }
      }
      sort: { order: ASC, fields: [childResources___title] }
    ) {
      nodes {
        fields {
          pathAuto
        }
        childResources {
          title
        }
      }
    }
    _books: allFile(
      filter: {
        fields: {
          pathAuto: { regex: "/^(?:(/books/))/" }
          autoNodeType: { eq: "resourceNode" }
          draftAuto: { eq: false }
        }
      }
      sort: { order: ASC, fields: [childResources___title] }
    ) {
      nodes {
        fields {
          pathAuto
        }
        childResources {
          title
        }
      }
    }
    _videos: allFile(
      filter: {
        fields: {
          pathAuto: { regex: "/^(?:(/videos/))/" }
          autoNodeType: { eq: "resourceNode" }
          draftAuto: { eq: false }
        }
      }
      sort: { order: ASC, fields: [childResources___title] }
    ) {
      nodes {
        fields {
          pathAuto
        }
        childResources {
          title
        }
      }
    }
    _additionalMaterials: allFile(
      filter: {
        fields: {
          pathAuto: { regex: "/^(?:(/additional-materials/))/" }
          autoNodeType: { eq: "resourceNode" }
          draftAuto: { eq: false }
        }
      }
      sort: { order: ASC, fields: [childResources___title] }
    ) {
      nodes {
        fields {
          pathAuto
        }
        childResources {
          title
        }
      }
    }
    _about: allFile(
      filter: {
        fields: {
          pathAuto: { regex: "/^(?:(/about/))/" }
          autoNodeType: { eq: "resourceNode" }
          draftAuto: { eq: false }
        }
      }
      sort: { order: ASC, fields: [childResources___title] }
    ) {
      nodes {
        fields {
          pathAuto
        }
        childResources {
          title
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
const Nav = React.memo(({ pathname }) => (
  <StaticQuery
    query={query}
    render={({
      _audioTalks: { nodes: audioTalksNodes },
      audioTalks = map(audioTalksNodes, flattenGatsbyNode),
      _lectures: { nodes: lecturesNodes },
      lectures = map(lecturesNodes, flattenGatsbyNode),
      _books: { nodes: booksNodes },
      books = map(booksNodes, flattenGatsbyNode),
      _videos: { nodes: videosNodes },
      videos = map(videosNodes, flattenGatsbyNode),
      _additionalMaterials: { nodes: additionalMaterialsNodes },
      additionalMaterials = map(additionalMaterialsNodes, flattenGatsbyNode),
      _about: { nodes: aboutNodes },
      about = map(aboutNodes, flattenGatsbyNode),
    }) => (
      <nav>
        <li>
          <Link to="/audio-talks" className="not-this">
            Audio Talks
          </Link>
          {strContains(pathname, 'audio-talks') === true && (
            <ul>
              {map(audioTalks, ({ pathAuto, title }) => (
                <li>
                  <Link to={pathAuto}>{title}</Link>
                </li>
              ))}
            </ul>
          )}
        </li>
        <li>
          <Link to="/lectures" className="not-this">
            Lectures
          </Link>
          {strContains(pathname, 'lectures') === true && (
            <ul>
              {map(lectures, ({ pathAuto, title }) => (
                <li>
                  <Link to={pathAuto}>{title}</Link>
                </li>
              ))}
            </ul>
          )}
        </li>
        <li>
          <Link to="/books" className="not-this">
            Books
          </Link>
          {strContains(pathname, 'books') === true && (
            <ul>
              {map(books, ({ pathAuto, title }) => (
                <li>
                  <Link to={pathAuto}>{title}</Link>
                </li>
              ))}
            </ul>
          )}
        </li>
        <li>
          <Link to="/videos" className="not-this">
            Videos
          </Link>
          {strContains(pathname, 'videos') === true && (
            <ul>
              {map(videos, ({ pathAuto, title }) => (
                <li>
                  <Link to={pathAuto}>{title}</Link>
                </li>
              ))}
            </ul>
          )}
        </li>
        <li>
          <Link to="/additional-materials" className="not-this">
            Additional Materials
          </Link>
          {strContains(pathname, 'additional-materials') === true && (
            <ul>
              {map(additionalMaterials, ({ pathAuto, title }) => (
                <li>
                  <Link to={pathAuto}>{title}</Link>
                </li>
              ))}
            </ul>
          )}
        </li>
        <li>
          <Link to="/about" className="not-this">
            About
          </Link>
          {strContains(pathname, 'about') === true && (
            <ul>
              {map(about, ({ pathAuto, title }) => (
                <li>
                  <Link to={pathAuto}>{title}</Link>
                </li>
              ))}
            </ul>
          )}
        </li>
        <li>
          <Link to="/" className="not-this">
            Homepage
          </Link>
        </li>
      </nav>
    )}
  />
))

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Nav
